import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
import { CustomEase } from 'gsap/CustomEase';
gsap.registerPlugin(CustomEase);

const setHero = () => {
	CustomEase.create('custom', '0.16, 1, 0.3, 1');
	jQuery(document).ready(function ($) {
		if ($('.under_hero').length > 0) {
			let heroTl = gsap.timeline({
				scrollTrigger: {
					trigger: '.under_hero',
					start: 'top top',
					end: '+=100%',
					//endTrigger: '#aboutContent',
					scrub: true,
					//markers: true,
				},
			});

			heroTl.to('.under_hero_img', {
				scale: 1,
			});
		}

		if ($('.cover_hero').length > 0) {
			let heroTl = gsap.timeline({
				scrollTrigger: {
					trigger: '.cover_hero',
					//pin: true,
					//pinSpacing: false,
					start: 'top top',
					end: '+=100%',
					scrub: true,
				},
			});

			heroTl
				.add('start')
				/* .to(
					'.cover_hero',
					{
						yPercent: 50,
					},
					'start'
				)
				.to(
					'.cover_hero_img',
					{
						yPercent: -10,
					},
					'start'
				) */
				.to(
					'.cover_hero_overlay',
					{
						opacity: 1,
					},
					'start'
				);
		}
	});
};

export { setHero };
