import Swiper from 'swiper';
import { FreeMode, Mousewheel } from 'swiper/modules';
import 'swiper/css';

import gsap from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
gsap.registerPlugin(CustomEase);

const setWorkGallery = () => {
	CustomEase.create('custom', '0.16, 1, 0.3, 1');

	jQuery(document).ready(function ($) {
		var galleryOpen = false,
			$body = $('body'),
			$gallery = $('.work_gallery'),
			galleryZoomed = false,
			gSwiper,
			$zoomCursor = $('.c-gallery');

		if ($('.gallery_utility_el').length > 0) {
			gsap.set('.gallery_utility_el', { yPercent: 100 });
		}

		$('.js-toggle-gallery').bind('click', function () {
			if (!galleryOpen) {
				$body.css('overflow', 'hidden');
				$body.addClass('gallery--open');
				let tl = gsap.timeline();
				tl.add('start')
					.to(
						'.gallery_utility_el',
						{
							duration: 1.5,
							ease: 'custom',
							yPercent: 0,
						},
						'start'
					)
					.to(
						'.work_gallery',
						{
							duration: 1.5,
							ease: 'custom',
							xPercent: -100,
						},
						'start'
					)
					.to(
						'.work_gallery_inner',
						{
							duration: 1.5,
							ease: 'custom',
							xPercent: -100,
						},
						'start+=0.7'
					);
			} else {
				$body.removeClass('gallery--open');
				let tl = gsap.timeline();
				tl.add('start')
					.to(
						'.gallery_utility_el',
						{
							duration: 1.5,
							ease: 'custom',
							yPercent: 100,
						},
						'start'
					)
					.to(
						'.work_gallery_inner',
						{
							duration: 1.5,
							ease: 'custom',
							xPercent: 0,
						},
						'start'
					)
					.to(
						'.work_gallery',
						{
							duration: 1.5,
							ease: 'custom',
							xPercent: 0,
							onComplete() {
								$body.css('overflow', 'auto');
								if (galleryZoomed) zoomGallery(!galleryZoomed);
								gSwiper.slideTo(0);
							},
						},
						'start+=0.7'
					);
			}
			galleryOpen = !galleryOpen;
		});

		function zoomGallery(zoom = true) {
			if (zoom) {
				gsap.to($gallery, {
					duration: 1.3,
					scale: 0.3,
					ease: 'custom',
				});
				galleryZoomed = true;
				$zoomCursor.text('zoom in');
			} else {
				gsap.to($gallery, {
					duration: 1.3,
					scale: 1,
					ease: 'custom',
				});
				galleryZoomed = false;
				$zoomCursor.text('zoom out');
			}
		}

		if ($('.gallery_swiper').length > 0) {
			var $gallery = $('.gallery_swiper');

			gSwiper = new Swiper('.gallery_swiper', {
				modules: [FreeMode, Mousewheel],
				slidesPerView: 'auto',
				longSwipesRatio: 0.2,
				freeMode: true,
				resizeObserver: false,
				centeredSlides: true,
				mousewheel: {
					enabled: true,
					sensitivity: 4,
				},
			});

			$('.work_gallery_inner').bind('click', function () {
				zoomGallery(!galleryZoomed);
			});
		}
	});
};

export { setWorkGallery };
