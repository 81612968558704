import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
import { CustomEase } from 'gsap/CustomEase';
gsap.registerPlugin(CustomEase);

const setMenu = () => {
	CustomEase.create('custom', '0.16, 1, 0.3, 1');

	jQuery(document).ready(function ($) {
		let $mainMenu = $('#mainMenu'),
			menuOpen = true;

		function toggleMenu(show = true) {
			if (show) {
				menuOpen = true;
				gsap.to($mainMenu, {
					duration: 0.5,
					width: '247px',
					height: '60px',
					ease: 'custom',
					onComplete: function () {
						$mainMenu.removeClass(
							'overflow-hidden pointer-events-none'
						);
					},
				});
			} else {
				menuOpen = false;
				gsap.to($mainMenu, {
					duration: 0.5,
					width: '0px',
					height: '30px',
					ease: 'custom',
				});
			}
		}

		ScrollTrigger.create({
			trigger: '#mainFooter',
			start: 'top bottom',
			end: 'bottom top',
			endTrigger: 'html',
			onToggle: (self) => {
				$mainMenu.addClass('overflow-hidden pointer-events-none');
				toggleMenu(!menuOpen);
			},
		});

		/* Toggle MENU */
		$('.wrapper').on('click', '.js-menuitem-toggle', function () {
			var $eTarget = $(this);
			if ($eTarget.hasClass('active')) {
				$eTarget.removeClass('active');
				$('.menu_item').removeClass('menu--open');
			} else {
				if ($('.menu--open').length > 0) {
					$('.menu--open .js-menuitem-toggle').removeClass('active');
					$('.menu_item').removeClass('menu--open');
				}
				var tg = $eTarget.attr('data-target');
				if (tg) {
					$(tg).addClass('menu--open');
					$eTarget.addClass('active');
				}
			}
		});

		//Index Menu
		$('.anchor_menu').bind('click', function () {
			$('.anchor_menu_container')[0].style.maxHeight = null;
		});
		$('.menu_item a').bind('click', function () {
			$('.menu_item').addClass('clicked');
		});

		/* Marquee */
		$('.marquee_text').each(function (index, el) {
			let rate = 25;
			let distance = el.clientWidth;
			let style = window.getComputedStyle(el);
			let marginRight = parseInt(style.marginRight) || 0;
			let totalDistance = distance + marginRight;
			let time = totalDistance / rate;
			let container = el.parentElement;

			gsap.to(container, time, {
				repeat: -1,
				x: '-' + totalDistance,
				ease: 'none',
			});
		});
	});
};

export { setMenu };
