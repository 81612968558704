const DEV = process.env.NODE_ENV === 'development';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { CustomEase } from 'gsap/CustomEase';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(ScrollToPlugin);

import { setSwiper } from '../scripts/swiper';
import { setStickyColumn } from '../scripts/sticky_column';
import { setHero } from '../scripts/hero';
import { setServices } from '../scripts/services';
import { setTeam } from '../scripts/team';
import { setStickyCenter } from '../scripts/sticky_center';
import { setWorkGallery } from '../scripts/gallery';
import { setClients } from '../scripts/clients';
import { setWorks } from '../scripts/works';
import { setContacts } from '../scripts/contacts';
import { setStickyitems } from '../scripts/sticky_items';
import { setIntro } from '../scripts/intro';
import { setMenu } from '../scripts/menu';
import { setCursor } from '../scripts/cursor';

export default {
	init() {
		// JavaScript to be fired on all pages
		console.log('init common');

		let isTouchDevice = false;

		// Remove no-js
		document.documentElement.classList.remove('no-js');
		document.documentElement.classList.add('js');

		// Test touch
		if (!('ontouchstart' in document.documentElement)) {
			document.documentElement.classList.add('no-touch');
		} else {
			document.documentElement.classList.add('touch');
			//ScrollTrigger.normalizeScroll(true);
			isTouchDevice = true;
		}

		CustomEase.create('custom', '0.16, 1, 0.3, 1');

		setIntro();

		jQuery(document).ready(function ($) {
			/* Toggle class */
			$('.wrapper').on('click', '.js-toggle-class', function (e) {
				e.preventDefault();
				var $eTarget = $(this);
				var tg = $eTarget.attr('data-target');
				var classname = $eTarget.attr('data-classname');
				if (tg && classname) {
					$(tg).toggleClass(classname);
				}
			});

			//TAB
			$('.js-toggle-tab').bind('click', function (e) {
				e.preventDefault();
				var tg = $(this).attr('data-target');
				if (tg) {
					$('.tab_panel').hide();
					$(tg).show();
					$('.js-toggle-tab').removeClass('active');
					$(this).addClass('active');
					$('.js-select-slide', tg).first().trigger('click');
					let firstItem = $('.js-toggle-accordion', tg).first();
					let tgFirtItem = $(firstItem).attr('data-target');
					if (!document.getElementById(tgFirtItem).style.maxHeight) {
						$(firstItem).trigger('click');
					}
				}
			});

			//Accordion
			const accordions = document.querySelectorAll(
				'.js-toggle-accordion'
			);
			if (accordions) {
				accordions.forEach((item, i) => {
					item.addEventListener('click', function (e) {
						this.classList.toggle('open');
						var tgId = this.dataset.target;
						var parentId = this.dataset.parent;
						if (tgId) {
							var panel = document.getElementById(tgId);
							if (panel.style.maxHeight) {
								panel.style.maxHeight = null;
							} else {
								if (parentId) {
									$('#' + parentId)
										.find('.js-toggle-accordion')
										.removeClass('open');
									$('#' + parentId)
										.find('.max-h-0')
										.each(function (i, item) {
											item.style.maxHeight = null;
										});
								}
								panel.style.maxHeight =
									panel.scrollHeight + 'px';
							}
						}
					});
				});
				$('.tab_panel .js-toggle-accordion').first().trigger('click');
			}
		});

		//FADE IN COMMON
		const boxes = gsap.utils.toArray('.fade-in');
		boxes.forEach((box, i) => {
			let delay = box.dataset.delay ? box.dataset.delay : 0;
			const anim = gsap.fromTo(
				box,
				{ autoAlpha: 0, y: 20 },
				{ duration: 1, delay: delay, autoAlpha: 1, y: 0 }
			);
			ScrollTrigger.create({
				trigger: box,
				start: 'top 90%',
				animation: anim,
				toggleActions: 'play none none none',
				once: true,
			});
		});

		//PARALLAX
		if (!isTouchDevice) {
			gsap.utils.toArray('[data-parallax]').forEach(function (container) {
				let image = container.querySelector('img');
				if (image) {
					gsap.to(image, {
						y: () => image.offsetHeight - container.offsetHeight,
						ease: 'none',
						scrollTrigger: {
							trigger: container,
							scrub: true,
							//scrub: 0.3,
							//invalidateOnRefresh: true,
						},
					});
				}
			});
		}

		//Anchors
		function getSamePageAnchor(link) {
			if (
				link.protocol !== window.location.protocol ||
				link.host !== window.location.host ||
				link.pathname !== window.location.pathname ||
				link.search !== window.location.search
			) {
				return false;
			}

			return link.hash;
		}

		function scrollToHash(hash, e) {
			const elem = hash ? document.querySelector(hash) : false;
			if (elem) {
				if (e) e.preventDefault();
				gsap.to(window, {
					scrollTo: elem,
					duration: 1,
					ease: 'custom',
				});
			}
		}

		document.querySelectorAll('a[href]').forEach((a) => {
			a.addEventListener('click', (e) => {
				scrollToHash(getSamePageAnchor(a), e);
			});
		});

		scrollToHash(window.location.hash);

		if (!isTouchDevice) setCursor();
		setSwiper();
		setStickyColumn();
		setHero();
		if (!isTouchDevice) setServices();
		setTeam();
		if (!isTouchDevice) setStickyCenter();
		setWorkGallery();
		setClients();
		setWorks();
		setContacts();
		if (!isTouchDevice) setStickyitems();
		setMenu();
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
		console.log('finalize common');
	},
};
