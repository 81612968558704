import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const setStickyCenter = () => {
	jQuery(document).ready(function ($) {
		if ($('.sticky_center').length > 0) {
			gsap.timeline({
				scrollTrigger: {
					trigger: '.sticky_trigger',
					start: 'top center',
					end: '+=600px',
					pin: '.sticky_center',
					//markers: true,
				},
			});
		}
	});
};

export { setStickyCenter };
