import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const setStickyColumn = () => {
	jQuery(document).ready(function ($) {
		var number = $('#columnNumber');
		$('.sticky_column_item').each(function () {
			var index = $(this).index();
			ScrollTrigger.create({
				trigger: $(this),
				start: 'top 30%',
				end: '+=70%',
				onToggle: (self) => {
					if (self.isActive) {
						number.html(index + 1);
					}
				},
			});
		});
	});
};

export { setStickyColumn };
