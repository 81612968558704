import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const setServices = () => {
	jQuery(document).ready(function ($) {
		if ($('.services_sticky').length > 0) {
			let servicesStickyTl = gsap.timeline({
				scrollTrigger: {
					trigger: '.services_sticky',
					start: 'top center',
					end: 'bottom bottom',
					pin: '.services_sticky_content',
					scrub: 0.5,
				},
			});

			servicesStickyTl.to('.services_list', {
				yPercent: -94,
			});

			let navLinks = gsap.utils.toArray('.services_list a');
			let panels = gsap.utils.toArray('.services_panel');

			panels.forEach((panel, i) => {
				ScrollTrigger.create({
					trigger: panel,
					start: 'top bottom',
					end: '+=100%',
					onEnter: () => {
						navLinks.forEach((e) => {
							e.classList.remove('active');
						});
						navLinks[i].classList.add('active');
					},
					onEnterBack: () => {
						navLinks.forEach((e) => {
							e.classList.remove('active');
						});
						navLinks[i].classList.add('active');
					},
				});
			});
		}
	});
};

export { setServices };
