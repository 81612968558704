import gsap from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
gsap.registerPlugin(CustomEase);

const setTeam = () => {
	CustomEase.create('custom', '0.16, 1, 0.3, 1');
	var isOpen = false,
		thumbsTw;

	jQuery(document).ready(function ($) {
		if ($('.team_thumb').length > 0) {
			gsap.set('.team_thumb', { filter: 'blur(30px)' });
			thumbsTw = gsap.to('.team_thumb', {
				duration: 1,
				opacity: 1,
				y: 0,
				filter: 'blur(0px)',
				ease: 'custom',
				stagger: 0.04,
				paused: true,
			});
		}

		$('.js-toggle-team').bind('click', function (e) {
			e.preventDefault();
			$('body').toggleClass('team--open');
			if (!isOpen) {
				gsap.delayedCall(0.8, function () {
					thumbsTw.play();
				});
			} else {
				thumbsTw.reverse();
			}

			isOpen = !isOpen;
		});
	});
};

export { setTeam };
