import gsap from 'gsap';
import Flip from 'gsap/Flip';
gsap.registerPlugin(Flip);

const setContacts = () => {
	jQuery(document).ready(function ($) {
		const reel = document.querySelector('#reel');
		if (reel) fixFlip();
		$('.js-toggle-reel').bind('click', function () {
			const state = Flip.getState(reel);
			var isOpen = reel.classList.toggle('full-screen');
			if (isOpen) {
				document.body.classList.add('overflow-hidden');
			}
			Flip.from(state, {
				absolute: true,
				absoluteOnLeave: true,
				onComplete() {
					if (!isOpen) {
						document.body.classList.remove('overflow-hidden');
					}
				},
			});
		});
	});

	function fixFlip() {
		let getState = Flip.getState;
		if (getState._fixed) return; // in case the fix was already applied
		Flip.getState = (targets, vars) => {
			let revert = [];
			gsap.utils.toArray(targets).forEach((target) => {
				if (
					!target.offsetParent &&
					gsap.getProperty(target, 'transform') === 'none'
				) {
					revert.push(target);
					target.style.transform = 'translateX(0.01px)';
				}
			});
			let state = getState(targets, vars);
			revert.forEach((t) => (t.style.transform = 'none'));
			return state;
		};
		Flip.getState._fixed = true;
	}
};

export { setContacts };
