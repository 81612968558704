import gsap from 'gsap';

const setClients = () => {
	jQuery(document).ready(function ($) {
		var $clients = $('.client_item'),
			$clientsBlock = $('.clients_block'),
			$filterBtns = $('.js-filter-industry');

		if ($clientsBlock.length > 0) {
			$filterBtns.bind('click', function (e) {
				e.preventDefault();
				var tax = $(this).attr('data-tax');
				$filterBtns.removeClass('active');
				$(this).addClass('active');
				if (tax) {
					var $el = $('.' + tax).removeClass(
						'inactive pointer-events-none'
					);
					$clients.not($el).addClass('inactive pointer-events-none');
				} else {
					$clients.removeClass('inactive pointer-events-none');
				}
			});

			$clients.each(function (index, item) {
				var image = $(item).find('.client_visual');
				if (image) {
					$(item)
						.bind('mouseenter', function () {
							$clientsBlock.addClass('onHover');
							gsap.to(image, {
								opacity: 1,
								duration: 0.5,
							});
						})
						.bind('mouseleave', function () {
							$clientsBlock.removeClass('onHover');
							gsap.to(image, {
								opacity: 0,
								duration: 0.5,
							});
						});
				}
			});
		}
	});
};

export { setClients };
