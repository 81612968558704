import Swiper from 'swiper';
import { EffectCreative, Thumbs } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-creative';
import 'swiper/css/thumbs';

const setSwiper = () => {
	jQuery(document).ready(function ($) {
		$('.slider_block').each(function () {
			var $sliderBlock = $(this);
			var idBlock = $sliderBlock.attr('id');

			const hSwiperThumbs = new Swiper('#thumbs' + idBlock, {
				slidesPerView: 'auto',
				slideToClickedSlide: true,
			});
			const hSwiper = new Swiper('#swiper' + idBlock, {
				modules: [EffectCreative, Thumbs],
				effect: 'creative',
				//loop: true,
				speed: 1200,
				allowTouchMove: false,
				creativeEffect: {
					prev: {
						shadow: true,
						translate: [0, '-20%', -1],
					},
					next: {
						translate: [0, '100%', 0],
					},
				},
				thumbs: {
					swiper: hSwiperThumbs,
				},
				on: {
					slideChange: function (swiper) {
						$sliderBlock
							.find('.js-select-slide')
							.removeClass('active');
						$sliderBlock
							.find(
								'.js-select-slide[data-slide="' +
									swiper.activeIndex +
									'"]'
							)
							.addClass('active');
					},
				},
			});

			$sliderBlock.on('click', '.js-select-slide', function () {
				$sliderBlock.find('.js-select-slide').removeClass('active');
				$(this).addClass('active');
				var slide = $(this).attr('data-slide');
				if (slide) {
					hSwiper.slideTo(slide);
				}
			});
		});
	});
};

export { setSwiper };
