import gsap from 'gsap';
import Flip from 'gsap/Flip';
gsap.registerPlugin(Flip);
import { CustomEase } from 'gsap/CustomEase';
gsap.registerPlugin(CustomEase);

const setIntro = () => {
	const intro = document.querySelector('#intro'),
		introText = document.querySelector('#introText'),
		introSlider = document.querySelector('#introSlider'),
		slider = document.querySelector('#introSliderInner'),
		mainMenu = document.querySelector('#mainMenu');

	if (intro) {
		document.body.classList.add('overflow-hidden');
		gsap.set(mainMenu, { y: 70 });
		CustomEase.create('custom', '0.16, 1, 0.3, 1');

		gsap.set(introText, { yPercent: 100, opacity: 1 });
		gsap.set(slider, { xPercent: 100, opacity: 1 });

		let tl = gsap.timeline();
		tl.add('start')
			.to(
				introText,
				{
					duration: 1,
					delay: 0.8,
					yPercent: 0,
					ease: 'custom',
				},
				'start'
			)
			.to(
				slider,
				{
					duration: 2,
					xPercent: -80,
					ease: 'custom',
					onComplete() {
						const state = Flip.getState(introSlider);
						introSlider.classList.add('full-screen');
						Flip.from(state, {
							duration: 1,
							ease: 'custom',
							absolute: true,
							onComplete() {
								document.body.classList.remove(
									'overflow-hidden'
								);
								intro.style.display = 'none';
								document.body.classList.add('completed');
								gsap.to(mainMenu, { y: 0, ease: 'custom' });
							},
						});
					},
				},
				'start+=1'
			);
	}
};

export { setIntro };
