import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
import Flip from 'gsap/Flip';
gsap.registerPlugin(Flip);
import { CustomEase } from 'gsap/CustomEase';
gsap.registerPlugin(CustomEase);

const setWorks = () => {
	CustomEase.create('custom', '0.16, 1, 0.3, 1');

	jQuery(document).ready(function ($) {
		var $btns = $('.works_btn'),
			$thumbs = $('.work_thumb'),
			$images = $('.work_thumb .work_thumb_visual'),
			$filtersBtns = $('.js-filter-cat'),
			isLoaded = false;

		if ($('.work_thumb').length > 0) {
			window.addEventListener('load', function () {
				isLoaded = true;
				showElements();
			});

			setTimeout(function () {
				if (!isLoaded) {
					showElements();
				}
			}, 1000);

			gsap.set('.work_thumb', { filter: 'blur(30px)' });
			let thumbsTw = gsap.to('.work_thumb', {
				duration: 1,
				opacity: 1,
				y: 0,
				filter: 'blur(0px)',
				ease: 'custom',
				stagger: 0.04,
				paused: true,
			});

			function showElements() {
				thumbsTw.play();
				$('#changeContain').trigger('click');
			}
		}

		if ($('.work_title').length > 0) {
			ScrollTrigger.create({
				trigger: '.work_title',
				start: 'bottom top',
				end: 'bottom top',
				endTrigger: 'html',
				//markers: true,
				onToggle: (self) => {
					if (self.isActive) {
						gsap.to('.fixed_filters', {
							duration: 0.4,
							yPercent: -100,
						});
					} else {
						gsap.to('.fixed_filters', {
							duration: 0.4,
							yPercent: 0,
						});
					}
				},
			});
		}

		$btns.bind('click', function () {
			$btns.removeClass('active');
			$(this).addClass('active');
			if ($(this).attr('id') == 'changeCover') {
				gsap.to($images, {
					duration: 1,
					ease: 'custom',
					height: '100%',
					width: '100%',
					scale: 1,
				});
			} else {
				$images.each(function (index, image) {
					gsap.to(image, {
						duration: 1,
						ease: 'custom',
						height: isVertical(image.src) ? '100%' : 'auto',
						width: isVertical(image.src) ? 'auto' : '100%',
						scale: 0.8,
					});
				});
			}
		});

		function isVertical(src) {
			var isvertical = true,
				img = new Image();
			img.src = src;

			if (img.naturalWidth >= img.naturalHeight) {
				isvertical = false;
			}

			return isvertical;
		}

		//FILTERS
		if ($('.js-filter-cat.active').length > 0) {
			var cat = $('.js-filter-cat.active').attr('data-cat');
			$filtersBtns.removeClass('active');
			$('[data-cat="' + cat + '"]').addClass('active');
			updateFilters(cat);
		}
		$filtersBtns.bind('click', function (e) {
			e.preventDefault();
			var cat = $(this).attr('data-cat');
			$filtersBtns.removeClass('active');
			$('[data-cat="' + cat + '"]').addClass('active');
			updateFilters(cat);
		});

		function updateFilters(cat) {
			const state = Flip.getState($thumbs);

			if (cat) {
				$thumbs.each(function (index, item) {
					if ($(item).hasClass(cat)) {
						$(item).css('display', 'block');
					} else {
						$(item).css('display', 'none');
					}
				});
			} else {
				$thumbs.each(function (index, item) {
					$(item).css('display', 'block');
				});
			}

			// animate from the previous state
			Flip.from(state, {
				duration: 0.5,
				absoluteOnLeave: true,
				//absolute: true,
				//ease: 'power1.inOut',
				onEnter: (elements) =>
					gsap.fromTo(
						elements,
						{ opacity: 0 },
						{ opacity: 1, duration: 0.3 }
					),
				onLeave: (elements) =>
					gsap.to(elements, {
						opacity: 0,
						duration: 0.3,
					}),
			});
		}
	});
};

export { setWorks };
