import CustomCursor from 'custom-cursor.js';

const setCursor = () => {
	jQuery(document).ready(function ($) {
		const cursorElement = document.querySelector('.cursor');
		const options = {
			hideTrueCursor: true,
			focusElements: [
				{
					elements: '.cursor-gallery',
					focusClass: 'cursor-gallery--focused',
				},
				{
					elements: '.cursor-thumb',
					focusClass: 'cursor-thumb--focused',
				},
				{
					elements: '.cursor-team',
					focusClass: 'cursor-team--focused',
				},
				{
					elements: 'button, a, .cursor-pointer',
					focusClass: 'cursor--focused',
					mouseenter: () => {
						customCursor.unhideTrueCursor();
					},
					mouseleave: () => {
						customCursor.hideTrueCursor();
					},
				},
			],
			focusClass: 'cursor--focused',
		};

		const customCursor = new CustomCursor(
			cursorElement,
			options
		).initialize();
	});
};

export { setCursor };
